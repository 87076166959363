import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { textAlign } from 'styled-system'

// container
import RehearsalContainer from './RehearsalContainer'

// components
import Section from '../components/layout/Section'
import { Day, Event, TitleText } from '../components/schedule'
import Button from '../components/controls/Button'
import ImageBox from '../components/media/ImageBox'
import { DustWest } from '../components/typography'
import Popup, { PopupLink } from '../components/Popup'

// theme
import { primary, secondary, greyLight } from '../components/colors'

// images
import Arrow from '../static/svg/arrow.svg'
import PresleyMotors from '../static/images/autoMuseumSchedule.jpg'
import Graceland from '../static/images/gracelandSchedule.jpg'

// styled components
const ArrowContainer = styled(Box).attrs({
    pb: 1,
})`
    svg {
        fill: ${secondary};
    }
`

const RightArrow = styled(Arrow)`
    transform: rotate(180deg);
`

const Column = styled(Flex)`
    ${textAlign};
`

const TextBlock = styled.p`
    margin-bottom: 1.25rem;
    max-width: 650px;
`

const BlockLink = styled.a`
    display: inline-block;
`

class ScheduleContainer extends Component {
    state = {
        showRehearsal: false,
    }

    handlePopupClick = () => {
        this.setState({
            showRehearsal: true,
        })
    }

    handleClose = () => {
        this.setState({
            showRehearsal: false,
        })
    }

    render() {
        return (
            <Section background={greyLight} name="ScheduleContainer">
                <Fragment>
                    <Flex
                        width={1}
                        py={5}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <ArrowContainer>
                            <Arrow />
                        </ArrowContainer>
                        <DustWest
                            px={[2, 3]}
                            size="h2"
                            color={secondary}
                            textAlign="center"
                        >
                            THE SCHEDULE
                        </DustWest>
                        <ArrowContainer>
                            <RightArrow />
                        </ArrowContainer>
                    </Flex>
                    <Flex
                        width={1}
                        px={1}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Day title="Friday, May 3" textAlign="center">
                            <Event
                                title="Rehearsal Dinner"
                                time="6PM"
                                textAlign="center"
                            >
                                <TitleText color={primary} textAlign="center">
                                    For Family & Wedding Party
                                </TitleText>
                                <TextBlock>
                                    We invite our Family and Wedding Party
                                    members to join us for a rehearsal dinner at
                                    the world famous Marlowe's Ribs & Restaurant
                                    for BBQ and cocktails!{' '}
                                    <PopupLink
                                        handleClick={this.handlePopupClick}
                                    >
                                        Click here for more information.
                                    </PopupLink>
                                </TextBlock>
                            </Event>
                        </Day>
                    </Flex>
                    <Flex flexDirection={['column', 'row']} mb={4}>
                        <Column
                            textAlign={['center', 'right']}
                            alignItems="flex-end"
                            flexDirection="column"
                            width={[1, 1 / 2]}
                            px={[1, 2]}
                        >
                            <Day title="Saturday, May 4">
                                <Fragment>
                                    <Event
                                        title="Ceremony"
                                        time="4:30PM"
                                        location="Graceland Chapel in the Woods"
                                    >
                                        <Fragment>
                                            <TextBlock>
                                                Adjacent to the Guest House at
                                                Graceland
                                                <br />
                                                3600 Elvis Presley Blvd
                                                <br />
                                                <a href="https://goo.gl/maps/JmYsb4uq1W32">
                                                    Find it on Google Maps
                                                </a>
                                            </TextBlock>
                                            <TextBlock>
                                                Chapel Parking is located at The
                                                Guesthouse next door.
                                                {/* TODO: Check parking locations for chapel */}
                                            </TextBlock>
                                        </Fragment>
                                    </Event>
                                    <Event
                                        title="Reception"
                                        time="6PM"
                                        location="Presley Motors Automobile Museum"
                                    >
                                        <Fragment>
                                            <TextBlock>
                                                Within Elvis Presley's Memphis
                                                Entertainment Complex
                                                <br />
                                                3717 Elvis Presley Blvd, Memphis
                                                <br />
                                                <a href="https://goo.gl/maps/JvqtHfQZBAr">
                                                    Find it on Google Maps
                                                </a>
                                            </TextBlock>
                                            <TextBlock>
                                                Transportation from the chapel
                                                will be available to all guests.
                                                Parking is also avilable at the
                                                main entrance to the Graceland
                                                complex.{' '}
                                                <BlockLink href="https://goo.gl/maps/UqgUbpdtBsS2">
                                                    Located here
                                                </BlockLink>
                                            </TextBlock>
                                            <ImageBox
                                                pt={2}
                                                src={PresleyMotors}
                                                alt="Presley Motors Automobile Museum"
                                            />
                                        </Fragment>
                                    </Event>
                                </Fragment>
                            </Day>
                        </Column>
                        <Column
                            textAlign={['center', 'left']}
                            alignItems="flex-start"
                            flexDirection="column"
                            width={[1, 1 / 2]}
                            px={[1, 2]}
                        >
                            <Day title="Sunday, May 5">
                                <Event
                                    title="OPTIONAL: Graceland Tour!"
                                    time="1PM"
                                >
                                    <Fragment>
                                        <TextBlock>
                                            It doesn’t matter if you’re a
                                            diehard fan or you’ve just heard of
                                            Elvis - Graceland mansion is a party
                                            house fit for a King! We’re going
                                            for our 3rd visit and we’d love for
                                            you to come along.
                                        </TextBlock>
                                        <Button
                                            mb={2}
                                            href="https://www.gofundme.com/sunday-may-5th-graceland-group-tour"
                                        >
                                            BOOK WITH US
                                        </Button>
                                        <ImageBox
                                            mb={2}
                                            src={Graceland}
                                            alt="Graceland tour interior"
                                        />
                                        <TitleText>
                                            2019 ELVIS EXPERIENCE TOUR +
                                            AIRPLANES
                                        </TitleText>
                                        <TextBlock>
                                            <strong>Group rate: $58.10</strong>{' '}
                                            &diams; Save 10% with a group of 15
                                            or more!
                                        </TextBlock>
                                        <TextBlock>
                                            <strong>
                                                Elvis Experience Tour +
                                                Airplanes Includes:
                                            </strong>
                                            <br />
                                            Graceland Mansion - Audio-Guided
                                            Tour with New
                                            <br />
                                            Orientation Film Elvis' Two Custom
                                            Airplanes
                                        </TextBlock>
                                        <TextBlock>
                                            Full Access to State-of-the-Art
                                            Visitor Entertainment Complex:
                                            <br />
                                            Elvis Presley Car Museum
                                            <br />
                                            Elvis: The Entertainer Career
                                            Showcase Museum
                                            <br />
                                            Elvis Discovery Exhibits
                                            <br />
                                        </TextBlock>
                                    </Fragment>
                                </Event>
                            </Day>
                        </Column>
                    </Flex>
                    <Popup
                        isOpen={this.state.showRehearsal}
                        onCloseClick={this.handleClose}
                        darkBackground
                    >
                        <RehearsalContainer />
                    </Popup>
                </Fragment>
            </Section>
        )
    }
}

export default ScheduleContainer
