import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { space } from 'styled-system'
import _times from 'lodash/times'

// theme
import { secondary, accent } from '../colors'

// components
import ImageBox from '../media/ImageBox'

// styled components
const Container = styled(Box)`
    color: ${secondary};
`

const LinkBox = styled.a`
    text-decoration: none;
    outline: none;
    &:hover {
        color: ${secondary};
        img {
            transform: scale(1.05);
        }
    }
`

const TitleText = styled.p`
    font-size: 20px;
    font-weight: bold;
    ${space};
`

const Star = styled.span`
    color: ${accent};
    padding-right: 2px;
`

const ShadowedImage = styled(Box)`
    img {
        transition: all 0.3s ease;
        transform: scale(1);
    }
`

const Accommodation = ({ title, stars = 0, image, site, rate, children }) => {
    return (
        <Container>
            <LinkBox href={site}>
                <Flex
                    mb={2}
                    flexDirection={['column', 'row']}
                    justifyContent={['center', 'flex-start']}
                    alignItems={['center', 'flex-start']}
                >
                    <TitleText mb={[0, 1]}>{title}</TitleText>
                    {stars > 0 ? (
                        <Box pt={[0, '2px']} ml={[0, 2]}>
                            {_times(stars, index => (
                                <Star key={index}>&#9733;</Star>
                            ))}
                        </Box>
                    ) : null}
                </Flex>
                <Box mb={2}>
                    <ShadowedImage>
                        <ImageBox
                            src={image}
                            alt={`accommodation photo for ${title}`}
                        />
                    </ShadowedImage>
                </Box>
            </LinkBox>
            <Box mb={2}>
                <p>
                    <strong>Average nightly rate: ${rate}</strong>
                </p>
            </Box>
            {children}
        </Container>
    )
}

export default Accommodation
