import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { textAlign } from 'styled-system'

// ui components
import Section from '../components/layout/Section'
import { DustWest, Cowboy } from '../components/typography'

// theme
import { accent, white } from '../components/colors'

// images
import floralBg from '../static/images/floralBgAttire.jpg'
import BlueHawaii from '../static/images/bluehawaiiAttire.jpg'
import PinkSuit from '../static/images/pinkSuitAttire.jpg'
import NudieSuit from '../static/images/nudieAttire.jpg'

// styled components

const Column = styled(Flex)`
    ${textAlign};
`

const TitleText = styled(Cowboy).attrs({
    color: white,
    size: 'h2',
    pb: 1,
})`
    letter-spacing: 2px;
`

const TextBlock = styled.p`
    color: ${white};
    margin-bottom: 1.25rem;
`

const ImageBlock = styled(Flex)`
    height: 100%;
`
const ImageColumn = styled(Box)`
    min-height: 260px;
    height: 100%;
    background: ${({ image }) => `url(${image}) no-repeat center`};
    background-size: cover;
`

const overlay = 'rgba(10,70,118,0.8)'
const backgroundOverlay = `linear-gradient(${overlay}, ${overlay}), url(${floralBg}) no-repeat center`

const AttireContainer = () => {
    return (
        <Section background={backgroundOverlay} name="AttireContainer">
            <Flex flexDirection={['column', 'row']} alignItems="stretch" py={5}>
                <Column
                    flexDirection="column"
                    textAlign={['center', 'left']}
                    width={[1, 1 / 2]}
                    px={[1, 2]}
                >
                    <TitleText>ATTIRE</TitleText>
                    <DustWest color={accent} size="h3">
                        WE WOULD LOVE FOR YOU TO ATTEND OUR WEDDING WEARING AS
                        MUCH BRIGHT COLOR AND PATTERNS AS POSSIBLE!
                    </DustWest>
                    <TextBlock>
                        Hawaiian print and aloha shirts encouraged.
                        <br />
                        <strike>"But beige is a color... "</strike>
                    </TextBlock>
                    <TextBlock>
                        <i>
                            Please note: Out of respect for memory of Elvis
                            Presley, we must request that there be no Elvis
                            impersonations on Graceland grounds.
                        </i>
                    </TextBlock>
                </Column>
                <Column
                    flexDirection="column"
                    textAlign={['center', 'left']}
                    width={[1, 1 / 2]}
                    px={[1, 2]}
                >
                    <ImageBlock justifyContent="space-between">
                        <ImageColumn image={BlueHawaii} width={[1 / 3.25]} />
                        <ImageColumn image={PinkSuit} width={[1 / 3.25]} />
                        <ImageColumn image={NudieSuit} width={[1 / 3.25]} />
                    </ImageBlock>
                </Column>
            </Flex>
        </Section>
    )
}

export default AttireContainer
