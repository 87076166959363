import styled, { css } from 'styled-components'
import { space, textAlign } from 'styled-system'

const headlines = {
    h1: css`
        font-size: 40px;
    `,
    h2: css`
        font-size: 36px;
    `,
    h3: css`
        font-size: 30px;
    `,
    h4: css`
        font-size: 22px;
    `,
}

const headline = css`
    line-height: 1.15;
    margin: 0;
    ${({ size }) => headlines[size]};
    color: ${({ color }) => color};
    ${space};
    ${textAlign};
`

export const Cowboy = styled.p`
    ${headline};
    font-family: 'Cowboys', 'Arial Black', 'Arial Bold', 'Helvetica', sans-serif;
`

export const BrandonPrinted = styled.p`
    ${headline};
    font-family: 'BrandonPrinted-One';
    line-height: 0.9;
`

export const DirtyHeadline = styled.p`
    ${headline};
    font-family: 'DirtyHeadline';
    line-height: 0.9;
`

export const DustWest = styled.p`
    ${headline};
    letter-spacing: 2px;
    font-family: 'DustWest', Impact;
    padding-top: 1px;
`

export const DeadSaloon = styled.p`
    ${headline};
    font-family: 'thedeadsaloon';
`
