import React from 'react'
import styled from 'styled-components'
import { Box } from '@rebass/grid'
import { space, textAlign } from 'styled-system'

// theme
import { primary, secondary } from '../colors'

// styled components
const DayContainer = styled(Box)`
    /* max-width: 400px; */
`

const EventContainer = styled(Box).attrs({
    mb: [3, 5],
})`
    text-align: inherit;
`

export const TitleText = styled.p`
    color: ${({ color }) => (color ? color : secondary)};
    font-size: 20px;
    font-weight: bold;
    ${space};
    ${textAlign};
`

const SubtitleText = styled.p`
    color: ${secondary};
    font-size: 16px;
    font-weight: bold;
    ${textAlign};
`

const Description = styled.div`
    color: ${secondary};
    font-size: 16px;
    ${textAlign};
`

export const Day = ({ title, children, textAlign }) => {
    return (
        <DayContainer>
            <TitleText color={primary} mb={'4px'} textAlign={textAlign}>
                {title}
            </TitleText>
            {children}
        </DayContainer>
    )
}

export const Event = ({ title, time, location, textAlign, children }) => {
    return (
        <EventContainer>
            <TitleText textAlign={textAlign}>
                {title} &diams; {time}
            </TitleText>
            {location ? (
                <SubtitleText textAlign={textAlign}>{location}</SubtitleText>
            ) : null}
            <Description textAlign={textAlign}>{children}</Description>
        </EventContainer>
    )
}

Event.defaultProps = {
    textAlign: 'inherit',
}
