import React from 'react'
import styled from 'styled-components'
import { Box } from '@rebass/grid'

const Container = styled(Box).attrs({
    width: 1,
})``

const Img = styled.img`
    width: 100%;
    height: auto;
    vertical-align: top;
`

const ImageBox = ({ src, alt, ...rest }) => {
    return (
        <Container {...rest}>
            <Img src={src} alt={alt} />
        </Container>
    )
}

export default ImageBox
