import React, { Component } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import axios from 'axios'

// components
import { Input, TextArea } from './Inputs'
import Button from '../controls/Button'
import { BrandonPrinted } from '../typography'
import { primary } from '../colors'

// styled components
const FieldSet = styled(Box).attrs({
    pb: 3,
    width: 1,
})``

const FieldLabel = styled(BrandonPrinted).attrs({
    size: 'h4',
    pb: 1,
})``

const SuccessBlock = styled(Flex)`
    text-align: center;
    padding-bottom: 1rem;
`

// util
const validEmail = email => {
    if (!email) return false
    const match = email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    return match ? true : false
}

class ContactForm extends Component {
    state = {
        name: '',
        email: '',
        message: '',
        validForm: false,
        submitting: false,
        errors: null,
        success: false,
    }

    updateValue = (input, value) => {
        this.setState(
            {
                [input]: value,
            },
            () => {
                this.checkValidForm()
            }
        )
    }

    checkValidForm = () => {
        const { name, email, message } = this.state
        this.setState({
            validForm: name && message && validEmail(email),
        })
    }

    submitForm = () => {
        const { name, email, message, validForm } = this.state
        if (!validForm) return
        this.setState(
            {
                submitting: true,
                errors: null,
            },
            () => {
                axios({
                    method: 'POST',
                    url:
                        'https://oh3e24li13.execute-api.us-east-1.amazonaws.com/prd/contactFormEmail',
                    data: {
                        name,
                        email,
                        message,
                    },
                })
                    .then(() => {
                        this.setState({
                            errors: null,
                            submitting: false,
                            success: true,
                        })
                    })
                    .catch(err => {
                        this.setState({
                            errors: err,
                            submitting: false,
                        })
                    })
            }
        )
    }

    render() {
        const { validForm, submitting, errors, success } = this.state
        return (
            <Flex flexDirection="column" width={1}>
                <FieldSet>
                    <FieldLabel>Name</FieldLabel>
                    <Input input="name" handleChange={this.updateValue} />
                </FieldSet>
                <FieldSet>
                    <FieldLabel>Email</FieldLabel>
                    <Input input="email" handleChange={this.updateValue} />
                </FieldSet>
                <FieldSet>
                    <FieldLabel>Message</FieldLabel>
                    <TextArea input="message" handleChange={this.updateValue} />
                </FieldSet>
                {success ? (
                    <SuccessBlock
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <BrandonPrinted size="h3" color={primary}>
                            Thank You!
                        </BrandonPrinted>
                        <p>We will get back to you as soon as possible.</p>
                    </SuccessBlock>
                ) : (
                    <Flex justifyContent={['center', 'flex-end']}>
                        {errors ? (
                            <p>There is an issue sending your message :(</p>
                        ) : null}
                        <Button
                            disabled={!validForm}
                            onClick={this.submitForm}
                            loading={submitting}
                        >
                            Send
                        </Button>
                    </Flex>
                )}
            </Flex>
        )
    }
}

export default ContactForm
