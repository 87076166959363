import React, { Component } from 'react'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'

// theme
import { primary, white, secondary, secondaryDark } from '../colors'

// styled components
const StyledOverlayContainer = styled(Flex)`
    position: fixed;
    width: 100%;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.45);
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    opacity: ${({ open }) => (open ? 1 : 0)};
    transition: opacity 0.2s ease;
`

const StyledPopupContent = styled(Box)`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    background-color: ${white};
    max-width: 900px;
    border-radius: 0;
    transition: transform 250ms ease ${({ open }) => (open ? '50ms' : '')};
    transform: translateY(${({ open }) => (open ? 0 : -10)}%);
    overflow: hidden;
`

const CloseButton = styled.button`
    display: inline-block;
    padding: 0;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    line-height: 0;
    position: absolute;
    z-index: 2;
    top: 1rem;
    right: 1rem;
    background: transparent;
    &:before,
    &:after {
        content: '';
        display: block;
        line-height: 0;
        width: 22px;
        height: 4px;
        overflow: hidden;
        transform: rotate(45deg);
        top: 10px;
        left: 1px;
        position: absolute;
        border-radius: 4px;
        background: ${({ darkBackground }) =>
            darkBackground ? 'rgba(255,255,255,0.75)' : secondary};
        transition: background 0.2s ease;
    }
    &:after {
        position: absolute;
        transform: rotate(-45deg);
        left: 1px;
        top: 10px;
    }

    &:hover {
        &:before,
        &:after {
            background: ${({ darkBackground }) =>
                darkBackground ? white : secondaryDark};
        }
    }
`

class Popup extends Component {
    handleBackgroundClick = event => {
        const { onCloseClick } = this.props
        if (event.currentTarget === event.target) {
            onCloseClick()
        }
    }

    render() {
        const {
            children,
            onCloseClick,
            isOpen,
            darkBackground,
            width = [1, '95%'],
        } = this.props

        return (
            <StyledOverlayContainer
                flexDirecton="column"
                justifyContent="center"
                alignItems="center"
                open={isOpen}
                onClick={this.handleBackgroundClick}
            >
                <StyledPopupContent
                    open={isOpen}
                    width={width}
                    position="relative"
                >
                    <CloseButton
                        onClick={onCloseClick}
                        darkBackground={darkBackground}
                    />
                    {children}
                </StyledPopupContent>
            </StyledOverlayContainer>
        )
    }
}

const ButtonLink = styled.button`
    display: inline-block;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: bold;
    outline: none;
    border: none;
    text-decoration: underline;
    background: transparent;
    padding: 0 2px;
    cursor: pointer;
    &:active,
    &:focus {
        color: ${primary};
    }
`

export const PopupLink = props => {
    const { children, handleClick } = props
    return <ButtonLink onClick={handleClick}>{children}</ButtonLink>
}

export default Popup
