import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Box } from '@rebass/grid'

// components
import { greyDark, greyLight } from '../colors'

// styled components
const Container = styled(Box)`
    position: relative;
    border: 1px solid ${({ focused }) => (focused ? greyDark : greyLight)};
`

const inputStyled = css`
    outline: none;
    border: none;
    width: 100%;
    font-size: 16px;
    font-family: 'Brandon', Arial, Helvetica, sans-serif;
    padding: 0.5rem 1rem;
`

const StyledInput = styled.input`
    ${inputStyled};
`

const StyledTextArea = styled.textarea.attrs({
    rows: 4,
})`
    ${inputStyled};
    resize: none;
    overflow: auto;
    vertical-align: top;
`

export class Input extends Component {
    state = {
        value: '',
        focused: false,
    }

    componentWillReceiveProps(props) {
        if (props.value !== this.state.value) {
            this.setState({ value: props.value })
        }
    }

    handleFocus = () => {
        this.setState({
            focused: true,
        })
    }

    handleBlur = () => {
        this.setState({
            focused: false,
        })
    }

    handleValueChange = event => {
        const {
            target: { value },
        } = event
        const { input, handleChange } = this.props
        if (value === this.state.value) {
            return false
        }

        this.setState({ value }, () => {
            handleChange(input, value)
        })
    }

    render() {
        const { focused, value } = this.state
        const {
            placeholder,
            width,
            component,
            type = 'text',
            readOnly,
        } = this.props
        const Component = component || StyledInput
        return (
            <Container width={width} focused={focused}>
                <Component
                    placeholder={placeholder}
                    onChange={this.handleValueChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    value={value}
                    type={type}
                    readOnly={readOnly}
                />
            </Container>
        )
    }
}

export const TextArea = props => {
    return <Input component={StyledTextArea} {...props} />
}
