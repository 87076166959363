import React, { Component } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { maxWidth, textAlign } from 'styled-system'

// ui components
import Section from '../components/layout/Section'
import ContactForm from '../components/forms/ContactForm'
import { DustWest, Cowboy } from '../components/typography'
import ImageBox from '../components/media/ImageBox'
import Popup from '../components/Popup'

// theme
import { primary, secondary, greyLight } from '../components/colors'

// images
import WhatsApp from '../static/images/iconWhatsapp.png'
import Facebook from '../static/images/iconFacebook.png'
import WeChat from '../static/images/iconWechat.png'
import WechatQR from '../static/images/wechatQR.jpg'
import StarsBar from '../static/images/smallStarsBar.png'

// styled components

const Column = styled(Flex)`
    ${textAlign};
`

const TitleText = styled(Cowboy).attrs({
    color: primary,
    size: 'h2',
    pb: 1,
})`
    letter-spacing: 2px;
`

const TextBlock = styled.p`
    padding-bottom: 1rem;
    max-width: 350px;
`

const Socials = styled(Flex).attrs({
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 1,
    maxWidth: 220,
    py: [2, 4],
    px: [2, 0],
    mb: [5, 0],
    mx: ['auto', 0],
})`
    ${maxWidth};
`

const SocialBox = styled(Box).attrs({
    width: 48,
})`
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
`

const backgroundOverlay = `url(${StarsBar}) repeat-x bottom, linear-gradient(${greyLight}, ${greyLight})`

class ContactContainer extends Component {
    state = {
        showQR: false,
    }

    toggleQR = () => {
        this.setState(state => ({
            showQR: !state.showQR,
        }))
    }

    render() {
        return (
            <Section
                background={backgroundOverlay}
                size="contain"
                name="ContactContainer"
            >
                <Flex
                    width={1}
                    py={5}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Flex
                        pt={[2, 4]}
                        mb={[0, 5]}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <DustWest px={3} size="h2" color={secondary}>
                            NEED TO GET IN TOUCH?
                        </DustWest>
                    </Flex>
                    <Flex
                        width={1}
                        flexDirection={['column', 'row']}
                        alignItems="stretch"
                        py={5}
                    >
                        <Column
                            flexDirection="column"
                            textAlign={['center', 'left']}
                            width={[1, 1 / 2]}
                            px={[1, 2]}
                        >
                            <TitleText>CONTACT US</TitleText>
                            <TextBlock>
                                If you have any questions or comments,
                                <br />
                                please don't hesitate to shoot us a message!
                            </TextBlock>
                            <TextBlock>
                                Or you can reach us through any of the
                                following:
                            </TextBlock>
                            <Socials>
                                <SocialBox>
                                    <a href="https://wa.me/310647066948">
                                        <ImageBox src={WhatsApp} />
                                    </a>
                                </SocialBox>
                                <SocialBox>
                                    <a href="https://m.me/BenderRodrigez">
                                        <ImageBox src={Facebook} />
                                    </a>
                                </SocialBox>
                                <SocialBox>
                                    <Box onClick={this.toggleQR}>
                                        <ImageBox src={WeChat} />
                                    </Box>
                                </SocialBox>
                            </Socials>
                            <Popup
                                isOpen={this.state.showQR}
                                onCloseClick={this.toggleQR}
                                width={360}
                            >
                                <Flex width={1} py={5} justifyContent="center">
                                    <Box width={300}>
                                        <ImageBox src={WechatQR} />
                                    </Box>
                                </Flex>
                            </Popup>
                        </Column>
                        <Column
                            flexDirection="column"
                            width={[1, 1 / 2]}
                            px={[1, 2]}
                        >
                            <ContactForm />
                        </Column>
                    </Flex>
                </Flex>
            </Section>
        )
    }
}

export default ContactContainer
