import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { fontSize, bottom, minHeight } from 'styled-system'
import { scroller } from 'react-scroll'

// img
import SunBurst from '../static/images/sunBurstWeb.gif'
import BerylHeadline from '../static/images/berylHeadline.png'
import SeanHeadline from '../static/images/seanHeadline.png'
import Arrow from '../static/svg/arrow.svg'
import DownFluer from '../static/svg/down-fluer.svg'

// ui components
import Section from '../components/layout/Section'
import { Cowboy, DustWest } from '../components/typography'

// theme
import { primary, secondary, accent, white } from '../components/colors'

// styled components
const Container = styled(Flex)`
    position: relative;
    ${minHeight};
    text-align: center;
`

const StyledCowboy = styled(Cowboy)`
    letter-spacing: 2px;
    ${fontSize};
`

const ArrowContainer = styled(Box)`
    svg {
        fill: ${primary};
    }
`
const RightArrow = styled(Arrow)`
    transform: rotate(180deg);
`

const NameContainer = styled(Box).attrs({
    width: [300, 600],
})`
    img {
        width: 100%;
        height: auto;
        vertical-align: top;
    }
`

const Location = styled(DustWest)`
    ${fontSize};
`

const RsvpContainer = styled(Flex)`
    position: absolute;
    ${bottom};
    &:hover {
        cursor: pointer;
    }
`

const RsvpText = styled.p`
    font-size: 20px;
    letter-spacing: 2px;
    font-family: 'Cowboys';
    color: ${white};
`

const bounceDown = keyframes`
	0%, 20%, 88%, 100% {
        transform: translateY(0);
    }
	36% {
        transform: translateY(-5px);
    }
    64% {
        transform: translateY(-2px);
    }
    48%, 76% {
        transform: translateY(5px);
    }
`
const bounceDownAnimation = css`
    ${bounceDown} 1.5s infinite;
`

const DownSymbol = styled(Box)`
    width: 40px;
    height: 33px;
    animation: ${bounceDownAnimation};
    svg {
        width: 100%;
        vertical-align: top;
        fill: ${primary};
        height: inherit;
    }
`

const MissingChar = styled.span`
    font-family: 'Brandon', Arial, Helvetica, sans-serif;
`

const backgroundOverlay = `url(${SunBurst}) no-repeat center, linear-gradient(${accent}, ${accent})`

const scrollTo = () => {
    const mobile = window.innerWidth < 768
    scroller.scrollTo('ScheduleContainer', {
        duration: 600,
        smooth: true,
        offset: mobile ? 0 : -42,
    })
}

const HeroContainer = () => {
    return (
        <Section
            background={backgroundOverlay}
            size={['cover', 'auto 100%']}
            name="HeroContainer"
        >
            <Container
                width={1}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                py={4}
                minHeight={[500, 720]}
            >
                <StyledCowboy
                    pb={1}
                    color={secondary}
                    fontSize={['20px', '22px']}
                >
                    You’re Invited
                    <br />
                    to the wedding of
                </StyledCowboy>
                <NameContainer>
                    <img src={BerylHeadline} alt="beryls head" />
                </NameContainer>
                <Flex py={1} justifyContent="center" alignItems="center">
                    <ArrowContainer>
                        <Arrow />
                    </ArrowContainer>
                    <StyledCowboy
                        px={1}
                        color={secondary}
                        fontSize={['20px', '22px']}
                    >
                        AND
                    </StyledCowboy>
                    <ArrowContainer>
                        <RightArrow />
                    </ArrowContainer>
                </Flex>
                <NameContainer>
                    <img src={SeanHeadline} alt="seans head" />
                </NameContainer>
                <Location
                    color={secondary}
                    pt={2}
                    pb={1}
                    fontSize={['28px', '36px']}
                >
                    AT GRACELAND IN MEMPHIS, TENNESSEE
                </Location>
                <StyledCowboy
                    color={primary}
                    pb={2}
                    fontSize={['20px', '22px']}
                >
                    May 3<MissingChar>-</MissingChar>5
                    <MissingChar>,</MissingChar> 2019
                </StyledCowboy>
                <RsvpContainer
                    mx="auto"
                    flexDirection="column"
                    alignItems="center"
                    bottom={[8, 24]}
                >
                    <DownSymbol pt={1} onClick={scrollTo}>
                        <DownFluer />
                    </DownSymbol>
                </RsvpContainer>
            </Container>
        </Section>
    )
}

export default HeroContainer
