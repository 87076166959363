import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { textAlign, display } from 'styled-system'

// ui components
import Section from '../components/layout/Section'
import Accommodation from '../components/Accommodation'
import { DustWest, Cowboy } from '../components/typography'
import ImageBox from '../components/media/ImageBox'

// theme
import { secondary, primary } from '../components/colors'

// images
import StarBars from '../static/images/largeStarsBar.gif'
import GuesthousePreview from '../static/images/guesthouseAccommodation.jpg'
import DaysInnPreview from '../static/images/daysInnAccommodation.jpg'
import SmallStarsBar from '../static/images/smallStarsBar.png'

// styled components

const Column = styled(Flex)`
    ${textAlign};
`

const HeadlineContainer = styled(Flex)`
    background: url(${StarBars}) no-repeat center;
    background-size: cover;
    min-height: 82px;
    text-align: center;
`

const DeadlineText = styled(Cowboy).attrs({
    color: primary,
    size: 'h4',
})`
    letter-spacing: 1px;
`
const MissingChar = styled.span`
    font-family: 'Brandon', Arial, Helvetica, sans-serif;
`

const Underlined = styled.span`
    text-decoration: underline;
`

const TextBlock = styled.p`
    margin-bottom: 1.25rem;
`

const DividerBar = styled(Box)`
    ${display};
`

const AccommodationsContainer = () => {
    return (
        <Section name="AccommodationsContainer">
            <Fragment>
                <HeadlineContainer
                    width={1}
                    mt={2}
                    mb={4}
                    py={[2, 0]}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <DustWest size="h2" color={secondary}>
                        HOTEL RECOMMENDATIONS
                    </DustWest>
                    <DeadlineText>
                        book with our party block before{' '}
                        <Underlined>
                            Monday
                            <MissingChar>,</MissingChar> April 1
                        </Underlined>
                    </DeadlineText>
                </HeadlineContainer>
                <Flex flexDirection={['column', 'row']} mb={4}>
                    <Column
                        flexDirection="column"
                        textAlign={['center', 'left']}
                        width={[1, 1 / 2]}
                        px={[1, 2]}
                    >
                        <Accommodation
                            title="The Guesthouse at Graceland"
                            stars={4}
                            image={GuesthousePreview}
                            site="https://guesthousegraceland.com/"
                            rate={180}
                        >
                            <TextBlock>
                                Booking Link:{' '}
                                <a href="https://gc.synxis.com/rez.aspx?Hotel=68721&Chain=10237&template=MEMTG&shell=MEMTG&arrive=5/3/2019&depart=5/5/2019&adult=1&child=0&group=190503CHUN">
                                    Chung-Kiefer Wedding
                                </a>
                                <br />
                                If you would like to make a reservation to
                                include an additional night, please call the
                                Reservation Department at{' '}
                                <a href="tel:+18002382000">
                                    1-800-238-2000
                                </a> or{' '}
                                <a href="tel:+19013443144">(901) 344-3144</a>.
                            </TextBlock>
                        </Accommodation>
                        <DividerBar display={['block', 'none']} py={[3, 0]}>
                            <ImageBox src={SmallStarsBar} />
                        </DividerBar>
                    </Column>
                    <Column
                        flexDirection="column"
                        textAlign={['center', 'left']}
                        width={[1, 1 / 2]}
                        px={[1, 2]}
                    >
                        <Accommodation
                            title="Days Inn by Wyndham at Graceland"
                            stars={2}
                            image={DaysInnPreview}
                            site="https://www.wyndhamhotels.com/days-inn/memphis-tennessee/days-inn-memphis-at-graceland/overview"
                            rate={108}
                        >
                            <TextBlock>
                                Call{' '}
                                <a href="tel:+18773612506">
                                    <strong>877-361-2506</strong>
                                </a>{' '}
                                for individual reservations-reference
                                confirmation number:{' '}
                                <strong>#5136B52011464</strong>.<br />
                                Free continental breakfast, free WiFi,
                                refrigerator and microwave
                            </TextBlock>
                        </Accommodation>
                    </Column>
                </Flex>
            </Fragment>
        </Section>
    )
}

export default AccommodationsContainer
