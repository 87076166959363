import BloodyCursor from './BloodyCursor.png'
import BloodSplatter from './BloodSplatter.gif'
import MKFont from './mortalkombat1.ttf'
import GetOver from './getover.wav'

let codeIndex = 0
let countDown
let Code
const Keypress = evt => {
    const letter = String.fromCharCode(evt.keyCode)
    if (letter === Code[codeIndex]) {
        clearTimeout(countDown)
        codeIndex++
        if (codeIndex === Code.length) {
            activate()
            resetCode()
        } else {
            countDown = setTimeout(() => {
                resetCode()
            }, 1000)
        }
    } else {
        clearTimeout(countDown)
        resetCode()
    }
}

const resetCode = () => {
    codeIndex = 0
}

const createStyleText = styleDefs => {
    let st = ''
    for (const selector in styleDefs) {
        st += '\n' + selector + ' {'
        const props = styleDefs[selector]
        for (const k in props) {
            if (props[k]) st += k + ':' + props[k] + ';'
            else st += k
        }
        st += '}'
    }
    return st
}

const attachStyles = () => {
    const cssStyles = {
        '@font-face': {
            'font-family': '"MKfont"',
            src: `url(${MKFont}); src: url(${MKFont}) format("truetype")`,
            'font-weight': 'normal',
            'font-style': 'normal',
        },
        'body, body *': {
            'font-family': '"MKfont" !important',
            cursor: 'none !important',
        },
        '#bloody-cursor': {
            position: 'fixed',
            height: '32px',
            width: '16px',
            overflow: 'visible',
            background: `url( ${BloodyCursor} ) left top`,
            animation: 'play 0.8s steps(4) infinite',
            'pointer-events': 'none',
        },
        '@keyframes play': {
            '100% { background-position: -64px; }': '',
        },
        '#blood-splatter': {
            position: 'absolute',
            right: '15px',
            bottom: '0',
            height: '74px',
            width: '65px',
            opacity: '0',
            background: `url( ${BloodSplatter} ) left top`,
            'pointer-events': 'none',
        },
        '#blood-splatter.splatter': {
            animation: 'splat 0.5s steps(11)',
        },
        '@keyframes splat': {
            '0% { opacity: 0; } 0.5% { opacity: 1; } 100% { background-position: -715px; }':
                '',
        },
    }
    // BUILD CSS CONTENT
    const styleText = createStyleText(cssStyles)
    // CREATE STYLE TAG ELEMENT
    const styleEl = document.createElement('style')
    styleEl.id = 'mk-styles'
    styleEl.setAttribute('type', 'text/css')
    // GET DOCUMENT HEAD
    const headEl =
        document.head ||
        document.getElementsByTagName('head')[0] ||
        document.documentElement
    headEl.appendChild(styleEl)

    // INSERT STYLE TEXT
    if (styleEl.styleSheet) {
        // IE
        styleEl.styleSheet.cssText = styleText
    } else {
        styleEl.textContent = styleText
    }
}

const attachCursor = () => {
    // CREATE CURSOR ELEMENT
    const cursorEl = document.createElement('div')
    cursorEl.id = 'bloody-cursor'

    // CREATE AND APPEND BLOOD SPLATTER ELEMENT
    const bloodSplatterEl = document.createElement('div')
    bloodSplatterEl.id = 'blood-splatter'
    cursorEl.appendChild(bloodSplatterEl)

    // APPEND CURSOR TO BODY
    document.body.appendChild(cursorEl)

    // CURSOR EVENTS
    const positionCursor = evt => {
        const x = evt.clientX
        const y = evt.clientY

        cursorEl.style.left = x + 'px'
        cursorEl.style.top = y + 'px'
    }
    const splatterBlood = () => {
        bloodSplatterEl.classList.add('splatter')
        setTimeout(() => {
            bloodSplatterEl.classList.remove('splatter')
        }, 500)
    }

    addEvent(window, 'mousemove', positionCursor)
    addEvent(window, 'mousedown', splatterBlood)
}

const activate = () => {
    // ATTACH BLOODCODE CSS TO DOM
    if (!document.getElementById('mk-styles')) {
        console.log('=== BLOODCODE ACTIVATED ===')
        attachStyles()
        attachCursor()
        if (document.getElementById('active-scorpion')) {
            setTimeout(() => {
                document.getElementById('active-scorpion').play()
            }, 200)
        }
    }
}

const addEvent = (element, eventName, callback) => {
    if (element.addEventListener) {
        element.addEventListener(eventName, callback, false)
    } else if (element.attachEvent) {
        element.attachEvent('on' + eventName, callback)
    }
}

const attachActivateAudio = () => {
    // ATTACH ACTIVATE WAVE
    const audioEl = document.createElement('audio')
    audioEl.id = 'active-scorpion'
    audioEl.setAttribute('src', GetOver)
    document.body.appendChild(audioEl)
}

const init = code => {
    Code = code || 'ABACABB'
    addEvent(window, 'keydown', Keypress)
    attachActivateAudio()
}

export default init
