import React, { Component, Fragment } from 'react'
import styled, { css } from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { display } from 'styled-system'
import { scroller } from 'react-scroll'

// components
import { DustWest } from '../components/typography'
import { primary, secondary, accent } from '../components/colors'

// constants
const sections = [
    { id: 'ScheduleContainer', name: 'Schedule' },
    { id: 'AccommodationsContainer', name: 'Accommodations' },
    { id: 'AttireContainer', name: 'Attire' },
    { id: 'RegistryContainer', name: 'Registry' },
    { id: 'AttractionsContainer', name: 'Attractions' },
    { id: 'ContactContainer', name: 'Contact' },
]

// styled
const Nav = styled(Flex)`
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;
`

const openDesktopNav = css`
    top: 0px;
    opacity: 1;
`
const DesktopNav = styled(Nav).attrs({
    display: ['none', 'flex'],
    justifyContent: 'center',
    width: 1,
})`
    ${display};
    background: ${accent};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    top: -42px;
    opacity: 0;
    transition: all 0.2s ease;
    ${({ show }) => (show ? openDesktopNav : null)};
`
const MobileNav = styled(Nav).attrs({
    display: ['block', 'none'],
    width: '3rem',
})`
    ${display};
`

const NavItems = styled(Flex).attrs({
    width: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
})`
    max-width: 768px;
`

const NavItem = styled(Box)`
    text-align: center;
    color: ${secondary};
    &:hover,
    &:active,
    &:focus {
        color: ${primary};
        cursor: pointer;
    }
`
const OpenButton = styled(Box)`
    width: 3rem;
    height: 3rem;
    background: ${accent};
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: 0.5rem;
`
const Hamburger = styled(Box)`
    top: 22px;
    left: 0.5rem;
    position: absolute;
    width: 2rem;
    height: 4px;
    border-radius: 4px;
    background: ${secondary};
    &:before,
    &:after {
        content: '';
        display: block;
        line-height: 0;
        width: 2rem;
        height: 4px;
        overflow: hidden;
        left: 0;
        position: absolute;
        border-radius: 4px;
        background: ${secondary};
    }
    &:before {
        top: -8px;
    }
    &:after {
        top: 8px;
    }
`

const MobileMenu = styled(Flex)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background: ${accent};
`

const CloseButton = styled(Box)`
    position: absolute;
    display: inline-block;
    padding: 0;
    width: 24px;
    height: 24px;
    line-height: 0;
    z-index: 2;
    top: 1rem;
    right: 1rem;
    background: transparent;
    &:before,
    &:after {
        content: '';
        display: block;
        line-height: 0;
        width: 22px;
        height: 4px;
        overflow: hidden;
        transform: rotate(45deg);
        top: 10px;
        left: 1px;
        position: absolute;
        border-radius: 4px;
        background: ${secondary};
    }
    &:after {
        position: absolute;
        transform: rotate(-45deg);
        left: 1px;
        top: 10px;
    }
`

class NavigationContainer extends Component {
    state = {
        showDesktopMenu: false,
        openMobileMenu: false,
    }

    handleScrollAndClose = section => {
        this.handleScroll(section)
        this.handleCloseMenu()
    }

    handleScroll = section => {
        scroller.scrollTo(section, {
            duration: 400,
            smooth: true,
            offset: this.state.openMobileMenu ? 0 : -42,
        })
    }

    handleOpenMenu = () => {
        this.setState({
            openMobileMenu: true,
        })
    }
    handleCloseMenu = () => {
        this.setState({
            openMobileMenu: false,
        })
    }

    detectShowMenu = () => {
        if (window.scrollY >= 150) {
            this.setState({
                showDesktopMenu: true,
            })
        } else {
            this.setState({
                showDesktopMenu: false,
            })
        }
    }

    componentDidMount() {
        document.addEventListener('scroll', this.detectShowMenu)
        if (window.scrollY >= 150) {
            this.setState({
                showDesktopMenu: true,
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.detectShowMenu)
    }

    render() {
        const { showDesktopMenu, openMobileMenu } = this.state
        return (
            <Fragment>
                <DesktopNav show={showDesktopMenu}>
                    <NavItems>
                        {sections.map(({ id, name }) => (
                            <NavItem
                                key={id}
                                onClick={() => {
                                    this.handleScroll(id)
                                }}
                                p={1}
                            >
                                <DustWest size="h4">{name}</DustWest>
                            </NavItem>
                        ))}
                    </NavItems>
                </DesktopNav>
                <MobileNav>
                    <OpenButton p={1} onClick={this.handleOpenMenu}>
                        <Hamburger />
                    </OpenButton>
                    {openMobileMenu && (
                        <MobileMenu py={5} flexDirection="column">
                            <CloseButton onClick={this.handleCloseMenu} />
                            {sections.map(({ id, name }) => (
                                <NavItem
                                    key={id}
                                    onClick={() => {
                                        this.handleScrollAndClose(id)
                                    }}
                                    p={1}
                                    my={1}
                                    width={1}
                                >
                                    <DustWest size="h3">{name}</DustWest>
                                </NavItem>
                            ))}
                        </MobileMenu>
                    )}
                </MobileNav>
            </Fragment>
        )
    }
}

export default NavigationContainer
