import React from 'react'
import { ThemeProvider } from 'styled-components'
import '../static/main.css'

const theme = {
    space: [0, 8, 16, 24, 32, 40],
    breakpoints: ['768px', '960px'],
}

const Layout = ({ children }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Layout
