import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { fontSize } from 'styled-system'

// components
import { DustWest } from '../components/typography'

// theme
import { white } from '../components/colors'

// images
import Marlowes from '../static/images/rehearsalMarlowes.jpg'

// styled components
const HeadlineContainer = styled(Flex)`
    background: url(${Marlowes}) no-repeat center;
    background-size: cover;
`

const LargeText = styled.p.attrs({
    fontSize: [16, 20],
})`
    color: ${white};
    ${fontSize};
    font-weight: 700;
    text-align: center;
`

const TextBlock = styled.p`
    max-width: 650px;
    text-align: center;
`

const SubtitleText = styled.p`
    font-weight: 700;
    text-align: center;
`

const RehearsalContainer = () => {
    return (
        <Box>
            <HeadlineContainer
                width={1}
                py={'4.5rem'}
                px={2}
                flexDirection="column"
                alignItems="center"
            >
                <DustWest
                    px={[2, 3]}
                    size="h1"
                    color={white}
                    textAlign="center"
                >
                    Welcome to the Wedding Party!
                </DustWest>
                <Box width={1} px={1}>
                    <LargeText>
                        Please join us for the Rehearsal Dinner
                        <br />
                        at Marlowe’s Ribs and Restaurant on Friday, May 3 at 6PM
                    </LargeText>
                </Box>
            </HeadlineContainer>
            <Flex width={1} py={3} flexDirection="column" alignItems="center">
                <Box px={1} pb={3}>
                    <TextBlock>
                        If you are staying at either of the recommended hotels
                        listed below, call Marlowe's for a free round trip ride
                        to and from the restaurant in one of their many Pink
                        Cadillac Limos.
                    </TextBlock>
                </Box>
                <Box px={1} pb={2}>
                    <SubtitleText>Marlowe's Ribs & Restaurant</SubtitleText>
                    <TextBlock>
                        (901) 332-4159 ♦ 4381 Elvis Presley Blvd
                        <br />
                        <a
                            href="https://goo.gl/maps/owbz56ujNc12"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Find it on Google Maps
                        </a>
                    </TextBlock>
                </Box>
            </Flex>
        </Box>
    )
}

export default RehearsalContainer
