import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { fontSize } from 'styled-system'

// ui components
import Section from '../components/layout/Section'
import { DustWest } from '../components/typography'

// theme
import { primary, accent, white } from '../components/colors'

// images
import StarsBar from '../static/images/smallStarsBar.png'
import Arrow from '../static/svg/arrow.svg'

// styled components

const ArrowContainer = styled(Box).attrs({
    pb: 1,
})`
    svg {
        fill: ${white};
    }
`

const RightArrow = styled(Arrow)`
    transform: rotate(180deg);
`

const LargeText = styled.p.attrs({
    fontSize: [16, 20],
})`
    ${fontSize};
    font-weight: 700;
    text-align: center;
`

const TextBlock = styled.p`
    max-width: 650px;
    text-align: center;
`

const backgroundOverlay = `url(${StarsBar}) repeat-x top, linear-gradient(${accent}, ${accent})`

const RegistryContainer = () => {
    return (
        <Section
            background={backgroundOverlay}
            size="contain"
            name="RegistryContainer"
        >
            <Flex
                width={1}
                py={5}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Flex
                    pt={[2, 4]}
                    mb={[4, 5]}
                    justifyContent="center"
                    alignItems="center"
                >
                    <ArrowContainer>
                        <Arrow />
                    </ArrowContainer>
                    <DustWest px={3} size="h2" color={primary}>
                        REGISTRY
                    </DustWest>
                    <ArrowContainer>
                        <RightArrow />
                    </ArrowContainer>
                </Flex>
                <Box mb={[3, 4]} px={1}>
                    <LargeText>
                        Your attendance is as good a gift as any!
                        <br />
                        We so appreciate you coming to Memphis from
                        <br />
                        far and wide to be with us this summer.
                        <br />
                    </LargeText>
                </Box>
                <Box px={1}>
                    <TextBlock>
                        Should you feel inclined to grace us with a little
                        something extra, please consider if it is light enough
                        to pack in a carry-on suitcase! In the 8 years we've
                        been together, we've lived in 5 different cities in 4
                        different countries and where we will end up in the
                        future is still uncertain. However, it is our dream to
                        one day own a home of our own (location TBD). If you
                        would like to contribute to our Future House Fund,{' '}
                        <a href="https://www.zola.com/registry/berylandsean">
                            visit our registry on Zola.
                        </a>
                    </TextBlock>
                </Box>
            </Flex>
        </Section>
    )
}

export default RegistryContainer
