import React from 'react'
import styled from 'styled-components'
import { Box } from '@rebass/grid'

// theme
import { secondary } from '../colors'

// components
import ImageBox from '../media/ImageBox'

// styled components
const Container = styled(Box)`
    color: ${secondary};
`

const LinkBox = styled.a`
    outline: none;
    &:hover {
        color: ${secondary};
        img {
            transform: scale(1.05);
        }
    }
`

const TitleText = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
    text-decoration: underline;
`

const ShadowedImage = styled(Box)`
    img {
        transition: all 0.3s ease;
        transform: scale(1);
    }
`

const Attraction = ({ title, image, site, children, address, map }) => {
    return (
        <Container mb={5}>
            <LinkBox href={site}>
                <Box mb={2}>
                    <ShadowedImage>
                        <ImageBox
                            src={image}
                            alt={`attraction photo for ${title}`}
                        />
                    </ShadowedImage>
                </Box>
                <TitleText>{title}</TitleText>
            </LinkBox>
            {children}
            {address ? <p>{address}</p> : null}
            {map ? <a href={map}>Find it on Google Maps</a> : null}
        </Container>
    )
}

export default Attraction
