import React from 'react'
import styled, { css } from 'styled-components'
import { space, width } from 'styled-system'

// components
import Loader from './Loader'
import { white, secondary, secondaryDark, greyDark, greyLight } from '../colors'

const alternateStyle = css`
    background: ${white};
    color: ${greyDark};
    border: 1px solid ${greyDark};
    &:hover,
    &:active,
    &:focus {
        color: ${greyDark};
        background: ${greyLight};
    }
`
const disabledStyled = css`
    opacity: 0.5;
    pointer-events: none;
`

const ButtonStyle = css`
    padding: 0.75rem 2.25rem;
    ${space};
    ${width};
    color: ${white};
    background: ${secondary};
    outline: none;
    border: none;
    transition: all 0.2s ease;
    &:hover,
    &:active,
    &:focus {
        color: ${white};
        cursor: pointer;
        background: ${secondaryDark};
    }
    ${({ alternate }) => (alternate ? alternateStyle : null)};
    ${({ disabled }) => (disabled ? disabledStyled : null)};
`

const ActionButton = styled.button`
    position: relative;
    ${ButtonStyle};
`
const LinkButton = styled.a`
    color: #fff;
    display: inline-block;
    text-decoration: none;
    ${ButtonStyle};
`

const Label = styled.span`
    display: inline-block;
    color: inherit;
    font-size: 22px;
    font-family: 'Brandon', Arial, Helvetica, sans-serif;
    line-height: 1.25;
    opacity: ${({ loading }) => (loading ? 0 : 1)};
`

const StyledLoader = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
`

const Button = ({ href, children, loading, disabled, ...rest }) => {
    return href ? (
        <LinkButton
            href={href}
            {...rest}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Label loading={loading}>{children}</Label>
        </LinkButton>
    ) : (
        <ActionButton disabled={disabled || loading} {...rest}>
            <Label loading={loading}>{children}</Label>
            {loading ? (
                <StyledLoader>
                    <Loader />
                </StyledLoader>
            ) : null}
        </ActionButton>
    )
}

export default Button
