import React from 'react'
import styled, { keyframes } from 'styled-components'

// theme
import { white } from '../colors'

// styled components
const Rotate = keyframes`
0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

const LoadingCircle = styled.svg`
    display: block;
    border-radius: 50%;
    animation: ${Rotate} 0.5s linear 0s infinite;
`

const Loader = props => {
    const { color = white } = props
    const sideLength = 24
    const stroke = sideLength / 8
    const cx = sideLength / 2
    const cy = cx
    const r = cx - stroke / 2
    const circ = r * 2 * Math.PI
    const dash_size = 0.25 * circ

    return (
        <div>
            <LoadingCircle height={sideLength} width={sideLength}>
                <circle
                    cx={cx}
                    cy={cy}
                    r={r}
                    stroke={color}
                    fill="transparent"
                    strokeWidth={stroke}
                    strokeDasharray={[dash_size, circ - dash_size]}
                    strokeLinecap="round"
                />
            </LoadingCircle>
        </div>
    )
}

export default Loader
