import React from 'react'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import { style } from 'styled-system'
import { Element } from 'react-scroll'

// styled
const backgroundSize = style({
    prop: 'backgroundSize',
    cssProperty: 'background-size',
})
const Container = styled(Flex)`
    background: ${props => props.background};
    ${backgroundSize};
    /* overflow: hidden; */
`

const Contents = styled(Box)`
    max-width: 60rem;
`

const Section = ({ background, size, name, children }) => {
    return (
        <Element name={name}>
            <Container
                background={background}
                backgroundSize={size}
                width={1}
                justifyContent="center"
            >
                <Contents width={1} py={2}>
                    {children}
                </Contents>
            </Container>
        </Element>
    )
}

Section.defaultProps = {
    background: '#fff',
    backgroundSize: 'cover',
}

export default Section
