import React, { Fragment } from 'react'
import { Flex } from '@rebass/grid'

// ui components
import Section from '../components/layout/Section'
import Attraction from '../components/Attraction'
import { DustWest, Cowboy } from '../components/typography'

// theme
import { primary, secondary } from '../components/colors'

// images
import BassPro from '../static/images/attractionPyramid.jpg'
import BealeSt from '../static/images/attractionBeale.jpg'
import Marlowes from '../static/images/attractionMarlowes.jpg'
import Peabody from '../static/images/attractionPeabody.jpg'
import Stax from '../static/images/attractionStax.jpg'
import SunStudio from '../static/images/attractionSunStudio.jpg'

// styled components

const AttractionsContainer = () => {
    return (
        <Section name="AttractionsContainer">
            <Fragment>
                <Flex
                    width={1}
                    pt={5}
                    pb={[4, 5]}
                    justifyContent="center"
                    alignItems={['center', 'flex-start']}
                    flexDirection={['column', 'row']}
                >
                    <DustWest
                        color={secondary}
                        size="h2"
                        pt={[0, '5px']}
                        pr={[0, '.75rem']}
                    >
                        WHAT TO DO IN
                    </DustWest>
                    <Cowboy color={primary} size="h2">
                        MEMPHIS
                    </Cowboy>
                </Flex>
                <Flex flexDirection={['column', 'row']} mb={4}>
                    <Flex flexDirection="column" width={[1, 1 / 2]} px={[1, 2]}>
                        <Attraction
                            title="Marlowe's Ribs & Restaurant"
                            image={Marlowes}
                            site="https://www.marlowesmemphis.com/"
                            address="4381 Elvis Presley Blvd"
                            map="https://goo.gl/maps/qjeC6W5JUv62"
                        >
                            <p>
                                We originally came here on a cross-country
                                roadtrip when we were 22 years old, and it's
                                even better than we remembered - the food is
                                better, the portions are bigger, the drinks are
                                stronger. Call (901) 332-4159 to catch a free
                                ride in one of their Pink Cadillac limos!
                            </p>
                        </Attraction>
                        <Attraction
                            title="Beale Street"
                            image={BealeSt}
                            site="http://www.bealestreet.com/"
                            map="https://goo.gl/maps/tGZz4cdYVv42"
                            address="Beale St. btwn Main and S 4th St."
                        >
                            <p>
                                Looking for live music and some "Big Ass" beers?
                                The heart of the Memphis party scene, Beale
                                Street is where you can find blues and booze
                                every 10 feet. Louis Armstrong, Muddy Waters, B.
                                B. King, and more blues and jazz legends played
                                on Beale Street, and there is still plenty of
                                local talent to be found here.
                            </p>
                        </Attraction>
                    </Flex>
                    <Flex flexDirection="column" width={[1, 1 / 2]} px={[1, 2]}>
                        <Attraction
                            title="Sun Studios"
                            image={SunStudio}
                            site="https://www.sunstudio.com/"
                            map="https://goo.gl/maps/1vAZRH536xF2"
                            address="706 Union Ave"
                        >
                            <p>
                                Known as "The Birthplace of Rock n' Roll", Sun
                                Studios is an absolute must-see in Memphis. View
                                the archives, learn the history, and stand in
                                the very same studio where country and
                                rockabilly greats including B.B. King, Howlin'
                                Wolf, Johnny Cash, Jerry Lee Lewis, and Elvis
                                Presley recorded some of music's most historic
                                tracks.
                            </p>
                        </Attraction>
                        <Attraction
                            title="Memphis Pyramid"
                            image={BassPro}
                            site="https://en.wikipedia.org/wiki/Memphis_Pyramid"
                            map="https://goo.gl/maps/42M6nSexAo12"
                            address="1 Bass Pro Dr"
                        >
                            <p>
                                An imposing structure with a tumultuous past.
                                Originally conceived in 1954, it has served as
                                the former home of The Memphis Grizzlies, a
                                concert venue, and now a 321 ft tall, 535,000
                                sq. ft mega Bass Pro shop. Features include a
                                hunting lodge, an archery & gun range, bowling
                                lane, laser tag, and a fully functional cypress
                                tree swamp with catfish and alligators. Even if
                                you’re not a hunter or fisherman, it’s still
                                worth a visit because this pyramid is
                                ridiculous.
                            </p>
                        </Attraction>
                    </Flex>
                    <Flex flexDirection="column" width={[1, 1 / 2]} px={[1, 2]}>
                        <Attraction
                            title="Stax(Records) Museum of American Soul"
                            image={Stax}
                            site="http://www.staxmuseum.com/"
                            map="https://goo.gl/maps/dkHZ5jPPGJG2"
                            address="926 E McLemore Ave"
                        >
                            <p>
                                "Hold on, I’m coming!" Sam and Dave, Otis
                                Redding, and Johnnie Taylor all got their start
                                here. Built at the site of the original Stax
                                Records, this museum will take you through the
                                history of soul music, as well as the stories of
                                the gospel, funk, and blues musicians that
                                passed through their doors. Issac Hayes' gold
                                chrome Superfly Cadillac on display is worth the
                                tour alone.
                            </p>
                        </Attraction>

                        <Attraction
                            title="The Peabody Hotel"
                            image={Peabody}
                            site="https://www.peabodymemphis.com"
                            map="https://goo.gl/maps/EhoPJbAHQ882"
                            address="118 S 2nd St"
                        >
                            <p>
                                A hotel so luxury that their lobby fountain is
                                occupied by resident pet ducks with their own
                                full-time caretaker. Dating back to 1940, every
                                day at 11am and 5pm you can visit the lobby to
                                see the famous Peabody Duck March, lead by the
                                official Peabody Duckmaster. Arrive early to get
                                a good view and grab a drink while you wait for
                                the show.
                            </p>
                        </Attraction>
                    </Flex>
                </Flex>
            </Fragment>
        </Section>
    )
}

export default AttractionsContainer
