import React, { Component, Fragment } from 'react'
import bloodcode from '../bloodcode'

// containers
import Layout from '../containers/Layout'
import NavigationContainer from '../containers/NavigationContainer'
import HeroContainer from '../containers/HeroContainer'
import ScheduleContainer from '../containers/ScheduleContainer'
import AccommodationsContainer from '../containers/AccommodationsContainer'
import AttireContainer from '../containers/AttireContainer'
import RegistryContainer from '../containers/RegistryContainer'
import AttractionsContainer from '../containers/AttractionsContainer'
import ContactContainer from '../containers/ContactContainer'

class IndexPage extends Component {
    componentDidMount() {
        bloodcode()
    }
    render() {
        return (
            <Layout>
                <Fragment>
                    <NavigationContainer />
                    <HeroContainer />
                    <ScheduleContainer />
                    <AccommodationsContainer />
                    <AttireContainer />
                    <RegistryContainer />
                    <AttractionsContainer />
                    <ContactContainer />
                </Fragment>
            </Layout>
        )
    }
}

export default IndexPage
